import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import './style.scss'
import {Col, Form, Row} from 'antd'
import {useEffect} from 'react'
import useUsers from '../../../../services/users/usersService'
import UserValidation from '../validation/UserValidation'
import {
  AutoCompleteComponent,
  InputForm,
  InputPasswordForm,
  SwitchForm,
} from '../../../../components/antd-components/form'
import {formProps} from '../../../../constants'
import CancelButton from '../../../../components/antd-components/main-buttons/cancel-button/CancelButton'
import SaveButton from '../../../../components/antd-components/main-buttons/save-button/SaveButton'

function UsersForm({
  formData = {
    id: null,
    phoneNumber: '',
    name: '',
    type: 'Seller',
    password: '',
    active: true,
  },
  onClose,
  text,
  form,
}) {
  const {id} = formData
  const {t} = useTranslation()
  const {modalProps} = formProps
  const schema = UserValidation()

  useEffect(() => {
    if (id) {
      form.setFieldsValue(formData)
      form.setFieldsValue({type: formData.type})
    }
  }, [id])

  const {addUser, updateUserData} = useUsers()
  const isLoading = useSelector((state) => state.units.isLoading === 'setUnits')

  const onSubmit = async (values) => {
    const {password, ...rest} = values
    if (!id) {
      await addUser(values)
    } else {
      await updateUserData({id, ...(password ? values : rest)})
    }
    onClose()
  }
  const layout = {
    labelCol: {span: 4},
    wrapperCol: {span: 24},
  }
  return (
    <Form
      name="basic"
      {...layout}
      form={form}
      onFinish={onSubmit}
      autoComplete="off"
    >
      <Row gutter={24}>
        <Col className="gutter-row" {...modalProps.colScreenProps}>
          <InputForm
            name="name"
            label={t('Full name')}
            rules={{...schema, required: true}}
            autoComplete="off"
          />
        </Col>
        <Col className="gutter-row" {...modalProps.colScreenProps}>
          <InputForm
            name="phoneNumber"
            label={t('Phone')}
            rules={{...schema, required: true}}
            placeholder="+380987770525"
          />
        </Col>
        <Col className="gutter-row" {...modalProps.colScreenProps}>
          <InputPasswordForm
            autocomplete="new-password"
            name="password"
            label={t('Password')}
            rules={{...schema, required: true}}
            placeholder={t('Password')}
          />
        </Col>
        <Col className="gutter-row" {...modalProps.colScreenProps}>
          <AutoCompleteComponent
            label={t('Type')}
            returnValue="name"
            initialValue="seller"
            options={[
              {
                id: 1,
                name: t('Seller'),
                label: 'seller',
              },
              {
                id: 2,
                name: t('Admin'),
                label: 'admin',
              },
            ]}
            name="type"
            placeholder={t('Type')}
            rules={{...schema, required: true}}
          />
        </Col>
        <Col className="gutter-row" {...modalProps.colScreenProps}>
          <SwitchForm
            name="active"
            label={t('Active')}
            size="large"
            initialValue={formData.active}
          />
        </Col>

        <Col className="button_wrapper_action button">
          <Col className="button">
            <CancelButton onClick={onClose} />
          </Col>
          <Col className="button">
            <SaveButton htmlType="submit" loading={isLoading} />
          </Col>
        </Col>
      </Row>
    </Form>
  )
}

export default UsersForm
