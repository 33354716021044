import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {formatUserName, getColumnSorter} from '../../utils/helpers'
import TagsComponent from '../../components/antd-components/tags/TagsComponent'
import EditActionButton from '../../components/antd-components/tableAction-buttons/edit-button/EditActionButton'
import DeleteActionButton from '../../components/antd-components/tableAction-buttons/delete-button/DeleteActionButton'

const useUnitsTable = ({onDelete, onEdit}) => {
  const {t} = useTranslation()
  const usersTableSettings = useSelector((state) => state.users.tableSettings)

  const getColumns = () => [
    {
      title: t('Full name'),
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      width: '25%',
      sorter: true,
      sortOrder: getColumnSorter(usersTableSettings, 'name'),
      filteredValue: '',
      render: (value) => formatUserName(value),
    },
    {
      title: t('Phone'),
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      align: 'center',
      width: '25%',
      filteredValue: '',
      sortOrder: getColumnSorter(usersTableSettings, 'phoneNumber'),
      sorter: true,
    },
    {
      title: t('Type'),
      dataIndex: 'type',
      key: 'f_type',
      align: 'center',
      width: '25%',
      sortOrder: getColumnSorter(usersTableSettings, 'type'),
      filteredValue: usersTableSettings.f_type,
      sorter: true,
      filters: [
        {
          text: t('Admin'),
          value: 'admin',
        },
        {
          text: t('Seller'),
          value: 'seller',
        },
      ],
      render: (_, record) => {
        if (record.type === 'admin') {
          return (
            <TagsComponent color="cyan" keys={record.id} tagText={t('Admin')} />
          )
        }
        return (
          <TagsComponent color="green" keys={record.id} tagText={t('Seller')} />
        )
      },
    },
    {
      title: t('Active'),
      dataIndex: 'active',
      key: 'f_active',
      align: 'center',
      width: '18%',
      sorter: true,
      filteredValue: usersTableSettings.f_active,
      sortOrder: getColumnSorter(usersTableSettings, 'active'),
      filters: [
        {
          text: t('Yes'),
          value: 'true',
        },
        {
          text: t('No'),
          value: 'false',
        },
      ],
      render: (_, record) => {
        if (record.active) {
          return (
            <TagsComponent color="green" keys={record.id} tagText={t('Yes')} />
          )
        }
        return <TagsComponent color="red" keys={record.id} tagText={t('No')} />
      },
    },

    {
      title: t('Actions'),
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      fixed: 'right',
      width: '7%',
      render: (_, record) => (
        <div>
          <EditActionButton
            onClick={(e) => {
              e.stopPropagation()
              onEdit(record.id)
            }}
          />
          <DeleteActionButton
            onClick={(e) => {
              e.stopPropagation()
              onDelete(record.id)
            }}
          />
        </div>
      ),
    },
  ]
  return {usersColumns: getColumns()}
}

export default useUnitsTable
